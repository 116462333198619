import React from "react";
import styled from "styled-components";
import { siteColors } from "../constants/colors";

const FooterContainer = styled.footer`
  padding-top: 2em;
  padding-left: 2em;
  padding-bottom: 1em;
  color: ${ siteColors.light };
  background-color: ${ siteColors.darkest };
`;

const Footer = () => (
  <FooterContainer>
    © {new Date().getFullYear()} Notional Pipe Incorporated
  </FooterContainer>
);

export default Footer;
