import React from "react";

const useViewport = () => {
    const initialWidth = !(typeof window === 'undefined') ? window.innerWidth : 1920;
    const [width, setWidth] =  React.useState(initialWidth);

    React.useEffect(() => {
        if (typeof window === 'undefined') { return () => null; }

        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
};

export { useViewport };
