import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { siteColors } from "../constants/colors";
import breakpoints from "../constants/breakpoints";
import { useViewport } from "../utils/viewport-hook";
import _ from "lodash";

const TopToolbar = styled(Toolbar)`
  border-bottom: 1px solid ${ siteColors.darkest};
  background: ${ siteColors.darker };
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OuterContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding-top: 1.33em;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.77em;
  white-space: nowrap;
`;

const HeaderTitle = styled.h1`
  margin: 0;
  font-size: 2em;
  color: $ { siteColors.light };
`;

const TitleLink = styled(Link)`
  color: ${ siteColors.light };
  text-decoration: None;
`;

const RightNav = styled.ul`
  vertical-align: bottom;
  margin-bottom: 0;
  line-height: 3em;
`;

const NavItem = styled.li`
  display: inline;
  padding-left: 1em;
  font-size: 1.4em;
  vertical-align: bottom;
  margin-bottom: 0;
  padding-bottom: 0;
`;

const MenuLink = styled(Link)`
  color: ${ siteColors.light };
  text-decoration: None;
`;

const StyledMenuIcon = styled(MenuIcon)`
   color: ${ siteColors.light };
`;

const StyledMenuItem = styled(MenuItem)`
   background-color: ${ siteColors.dark };
   color: ${ siteColors.light };
   font-family: georgia, serif ;
`;

const StyledMenu = styled(Menu)`
  && {
    & .MuiMenu-paper{
      background-color: ${siteColors.dark};
    }
    & .MuiMenu-list{
      background-color: ${siteColors.dark};
    }
  }
`;

const navItems = [
    {
        label: 'Home',
        to: '/',
    },
    {
        label: 'Get Contour',
        to: '/software',
    },
    {
        label: 'Case Studies',
        to: '/case-studies',
    },
    {
        label: 'Learn',
        to: '/learn',
    },
    {
        label: 'FAQ',
        to: '/faq',
    },
    {
        label: 'Contact',
        to: '/contact',
    },
    {
        label: 'About Us',
        to: '/about',
    },
];

const DesktopNav = () => (
        <RightNav>
        {_.map(navItems, (item) =>
                <NavItem key={item.label}>
                <MenuLink to={item.to}>{item.label}</MenuLink>
                </NavItem>
        )}
            </RightNav>
 );

const MobileNav = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
        <IconButton
    aria-label="more"
    aria-controls="long-menu"
    aria-haspopup="true"
    onClick={handleClick}
        >
        <StyledMenuIcon />
            </IconButton>
    <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
            >
            {_.map(navItems, (item) =>
                    <StyledMenuItem key={item.label} onClick={handleClose}
                component={Link}
                to={item.to}
                    >{ item.label }</StyledMenuItem>

            )}
            </StyledMenu>
            </>
    );
 };

const NavSwitcher = ({ width }) => (
    width < breakpoints.desktopXSmallHD ? <MobileNav /> : <DesktopNav />
);


const Header = ({ siteTitle }) => {
    const width = useViewport().width;

    return (
  <>
    <TopToolbar>
      <OuterContainer>
        <HeaderTitle>
          <TitleLink to="/">
            {siteTitle}
          </TitleLink>
        </HeaderTitle>
            <NavSwitcher width={width} />
      </OuterContainer>
    </TopToolbar>
            </>
    );
 };

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
