const siteColors = {
    light: "#EFF1F0",
    lightAccent: "#60A9E1",
    main: "#2788ED",
    medium: "#1c3887",
    darkAccent: "#6D578C",
    dark: "#112C6E",
    darker: "#0C1A40",
    darkest: "#040814",
};

const mediumMonochromes = {
    one: "#1c3887",
    two: "#2c407a",
    three: "#38466e",
    four: "#404961"
}

export { siteColors, mediumMonochromes }
