export default {
    desktopMediumHD: 1505,
  desktopSmallHD: 1316,
    desktopXSmallHD: 1120,
  desktopSD: 1024,
    half1080: 960,
    iPad: 768,
    xLargePhone: 604,
    mediumPhone: 414,
};
